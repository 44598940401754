import React from "react";
// import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            About Me
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            {/* <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Peter Ciluzzi</span>, a Web App Developer
              
            </h2> */}
            <p className={darkTheme ? "text-light fw-semibold text-4" : ""}>
              I'm a software engineer specializing in full-stack MERN/React application development.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
            I provide end to end oversight of creating web applications, from initial concept through deployment and maintenance -       
            including development of application language and content, user interface design and layout, back-end architecture, 
            testing and cyber security implementation, and site optimization & web performance.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I've also been a professional musician and guitar builder for more than 20 years. As guitarist and composer 
              I've released several albums of original music on Candyrat Records, toured and performed throughout the US, 
              accumulated millions of plays on streaming platforms such as Spotify, and have been featured in numerous publications 
              including Acoustic Guitar magazine. 
              Learn more at <a href="http://www.peterciluzzi.com" target="_blank" rel="noreferrer">www.peterciluzzi.com</a>.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Having studied Contemporary Guitar Design & Construction at The American School of Lutherie, 
              I founded Ciluzzi Guitars in 2006 and my work has been displayed at a number of exhibitions 
              including the Montreal Guitar Show. 
              To learn more please visit <a href="http://www.ciluzziguitars.com" target="_blank" rel="noreferrer">www.ciluzziguitars.com</a>.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <div className="text-center text-lg-start pb-4 pb-lg-3">
                <img className="headshot" src="images/am-headshot-2.png" alt="" />
              </div>
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Peter Ciluzzi
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:chat@simone.com">peter@peterciluzzi.dev</a>
                </li>
                {/* <li>
                  <span className="fw-600 me-2">Age:</span>28
                </li> */}
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Provincetown,
                  Massachusetts
                </li>
              </ul>
              <a
                href="peter-ciluzzi-resume-12-17-24.pdf"
                // download
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary rounded-pill"
              >
                View Resume
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          {/* <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>4</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experience
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>
          </div> */}
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
